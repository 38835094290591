@import '~scss/variables.scss';

.progressHeader {
	background: $color-gray-progressHeader;
	min-height: 14rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.container {
		@include padding-medium-vertical;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
	.currentStep {
		font-weight: 100;
		@include tablet-down {
			display: none;
		}
	}
	.heading {
		margin: 0;
		text-transform: none;
	}
}

.progressNav {
	display: flex;
	justify-content: stretch;
	width: 100%;
	a,
	.disabledLink {
		background: #dbdfe1;
		margin-right: 0.3rem;
		height: 1.1rem;
		display: block;
		width: 100%;
		transition: all 0.2s ease;
		&:last-of-type {
			margin-right: 0;
		}
		&.active {
			background: $color-blue-dark;
		}
	}
	a {
		&:hover {
			background: $color-blue-light;
		}
	}
}

.subtitle {
	margin-bottom: 0.4rem;
}

.arrow {
	transform: scaleY(-1) translateY(-16px);
	margin-left: 0.8rem;
}
