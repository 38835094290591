@import '~scss/variables.scss';

.inputMessage {
	display: flex;
	margin-top: $size-4;
}

.icon {
	margin-right: 0.8rem;
	position: relative;
	top: 0.2rem;
}

.text {
	margin: 0;
	font-size: $size-12;
	color: $color-gray-3;
}

.error {
	color: $color-red-base;
}
