@import '~scss/variables.scss';

html {
	box-sizing: border-box;
	font-size: 62.5%;
}
*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	min-height: 100vh;
}

@include set-accessibility-focus {
	*:focus:not([type='text']) {
		box-shadow: none !important;
		outline: none;
	}
}

hr {
	border-color: rgba(31, 49, 96, 0.2);
	@include margin-medium-vertical;
}

.inheritLink {
	color: inherit;
	text-decoration: none;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
}

.screenReaderText {
	@include screen-reader-text;
}
