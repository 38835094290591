@mixin basicTable {
	max-width: 100%;
	width: 100%;
	border: none;
	border-spacing: 0;
	@include tablet-up {
		table-layout: fixed;
	}
	thead,
	tbody {
		min-width: 100%;
	}
	th {
		text-transform: uppercase;
		text-align: left;
		line-height: 1.3;
	}
	td,
	th {
		border-bottom: 0.1rem solid white;
		font-size: 1.2rem;
	}
}

.tableWrapper {
	overflow: auto;
	max-width: 100%;
}
