// Padding and margin rules
$spacing-small-vertical: $size-16;
$spacing-small-vertical-mobile: $size-12;
$spacing-small-horizontal: $size-16;
$spacing-small-horizontal-mobile: $size-12;

$spacing-medium-vertical: $size-32;
$spacing-medium-vertical-mobile: $size-24;
$spacing-medium-horizontal: $size-32;
$spacing-medium-horizontal-mobile: $size-24;

$spacing-large-vertical: 6rem;
$spacing-large-vertical-mobile: $size-32;
$spacing-large-horizontal: $size-48;
$spacing-large-horizontal-mobile: $size-24;

@mixin margin-small-top {
	margin-top: $spacing-small-vertical-mobile;
	@include tablet-up {
		margin-top: $spacing-small-vertical;
	}
}

@mixin margin-small-right {
	margin-right: $spacing-small-horizontal-mobile;
	@include tablet-up {
		margin-right: $spacing-small-horizontal;
	}
}

@mixin margin-small-bottom {
	margin-bottom: $spacing-small-vertical-mobile;
	@include tablet-up {
		margin-bottom: $spacing-small-vertical;
	}
}

@mixin margin-small-left {
	margin-left: $spacing-small-horizontal-mobile;
	@include tablet-up {
		margin-left: $spacing-small-horizontal;
	}
}

@mixin margin-medium-top {
	margin-top: $spacing-medium-vertical-mobile;
	@include tablet-up {
		margin-top: $spacing-medium-vertical;
	}
}

@mixin margin-medium-right {
	margin-right: $spacing-medium-horizontal-mobile;
	@include tablet-up {
		margin-right: $spacing-medium-horizontal;
	}
}

@mixin margin-medium-bottom {
	margin-bottom: $spacing-medium-vertical-mobile;
	@include tablet-up {
		margin-bottom: $spacing-medium-vertical;
	}
}

@mixin margin-medium-left {
	margin-left: $spacing-medium-horizontal-mobile;
	@include tablet-up {
		margin-left: $spacing-medium-horizontal;
	}
}

@mixin margin-large-top {
	margin-top: $spacing-large-vertical-mobile;
	@include tablet-up {
		margin-top: $spacing-large-vertical;
	}
}

@mixin margin-large-right {
	margin-right: $spacing-large-horizontal-mobile;
	@include tablet-up {
		margin-right: $spacing-large-horizontal;
	}
}

@mixin margin-large-bottom {
	margin-bottom: $spacing-large-vertical-mobile;
	@include tablet-up {
		margin-bottom: $spacing-large-vertical;
	}
}

@mixin margin-large-left {
	margin-left: $spacing-large-horizontal-mobile;
	@include tablet-up {
		margin-left: $spacing-large-horizontal;
	}
}

@mixin padding-small-top {
	padding-top: $spacing-small-vertical-mobile;
	@include tablet-up {
		padding-top: $spacing-small-vertical;
	}
}

@mixin padding-small-right {
	padding-right: $spacing-small-horizontal-mobile;
	@include tablet-up {
		padding-right: $spacing-small-horizontal;
	}
}

@mixin padding-small-bottom {
	padding-bottom: $spacing-small-vertical-mobile;
	@include tablet-up {
		padding-bottom: $spacing-small-vertical;
	}
}

@mixin padding-small-left {
	padding-left: $spacing-small-horizontal-mobile;
	@include tablet-up {
		padding-left: $spacing-small-horizontal;
	}
}

@mixin padding-medium-top {
	padding-top: $spacing-medium-vertical-mobile;
	@include tablet-up {
		padding-top: $spacing-medium-vertical;
	}
}

@mixin padding-medium-right {
	padding-right: $spacing-medium-horizontal-mobile;
	@include tablet-up {
		padding-right: $spacing-medium-horizontal;
	}
}

@mixin padding-medium-bottom {
	padding-bottom: $spacing-medium-vertical-mobile;
	@include tablet-up {
		padding-bottom: $spacing-medium-vertical;
	}
}

@mixin padding-medium-left {
	padding-left: $spacing-medium-horizontal-mobile;
	@include tablet-up {
		padding-left: $spacing-medium-horizontal;
	}
}

@mixin padding-large-top {
	padding-top: $spacing-large-vertical-mobile;
	@include tablet-up {
		padding-top: $spacing-large-vertical;
	}
}

@mixin padding-large-right {
	padding-right: $spacing-large-horizontal-mobile;
	@include tablet-up {
		padding-right: $spacing-large-horizontal;
	}
}

@mixin padding-large-bottom {
	padding-bottom: $spacing-large-vertical-mobile;
	@include tablet-up {
		padding-bottom: $spacing-large-vertical;
	}
}

@mixin padding-large-left {
	padding-left: $spacing-large-horizontal-mobile;
	@include tablet-up {
		padding-left: $spacing-large-horizontal;
	}
}

@mixin margin-small-vertical {
	@include margin-small-top;
	@include margin-small-bottom;
}
@mixin padding-small-vertical {
	@include padding-small-top;
	@include padding-small-bottom;
}

@mixin margin-medium-vertical {
	@include margin-medium-top;
	@include margin-medium-bottom;
}

@mixin padding-medium-vertical {
	@include padding-medium-top;
	@include padding-medium-bottom;
}

@mixin margin-large-vertical {
	@include margin-large-top;
	@include margin-large-bottom;
}

@mixin padding-large-vertical {
	@include padding-large-top;
	@include padding-large-bottom;
}

@mixin margin-small-horizontal {
	@include margin-small-right;
	@include margin-small-left;
}

@mixin padding-small-horizontal {
	@include padding-small-right;
	@include padding-small-left;
}

@mixin margin-medium-horizontal {
	@include margin-medium-right;
	@include margin-medium-left;
}

@mixin padding-medium-horizontal {
	@include padding-medium-right;
	@include padding-medium-left;
}

@mixin margin-large-horizontal {
	@include margin-large-right;
	@include margin-large-left;
}

@mixin padding-large-horizontal {
	@include padding-large-right;
	@include padding-large-left;
}

@mixin padding-small {
	@include padding-small-vertical;
	@include padding-small-horizontal;
}

@mixin margin-small {
	@include margin-small-vertical;
	@include margin-small-horizontal;
}

@mixin padding-medium {
	@include padding-medium-vertical;
	@include padding-medium-horizontal;
}

@mixin margin-medium {
	@include margin-medium-vertical;
	@include margin-medium-horizontal;
}

@mixin padding-large {
	@include padding-large-vertical;
	@include padding-large-horizontal;
}

@mixin margin-large {
	@include margin-large-vertical;
	@include margin-large-horizontal;
}
