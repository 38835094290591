@import '~scss/variables';

.pagination {
	display: flex;
	width: min-content;
	margin-left: auto;
	@include tablet-up {
		background: none;
	}
	.item {
		display: none;

		@include tablet-up {
			display: block;
		}
		&.prev,
		&.next {
			display: block;
			a {
				@include all-svg-elements {
					stroke: $color-blue-base !important;
				}
				&:hover:not(.disabled) {
					background-color: transparent;
					@include all-svg-elements {
						stroke: $color-green-light !important;
					}
				}
			}
		}

		&:first-of-type a {
			margin-left: 0;
		}
		&:last-of-type a {
			margin-right: 0;
		}
		&.mobileLabel {
			display: block;
			@include tablet-up {
				display: none;
			}
			.mobileLabelInner {
				padding-right: 0;
			}
		}
		a,
		.mobileLabelInner,
		.disabled {
			line-height: 1;
			min-width: $size-40;
			min-height: $size-40;
			transition: all 0.3s ease;
			display: flex;
			align-items: center;
			text-align: center;
			justify-content: center;
			border-radius: 50%;
			text-decoration: none;
			color: $color-gray-1;

			&:hover:not(.disabled) {
				background-color: $color-blue-light;
				cursor: pointer;
				color: $color-white;
			}
			&:active {
				color: $color-gray-1;
			}
		}

		.disabled {
			color: $color-gray-1;
			svg {
				@include all-svg-elements {
					stroke: $color-gray-4 !important;
				}
			}
		}
		&.current {
			display: block;
			@include tablet-up {
				a {
					background-color: $color-green-light;
					&:focus,
					&:hover {
						color: $color-gray-1;
						background-color: $color-green-light;
					}
				}
			}
		}
	}
}
