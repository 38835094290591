@import '~scss/variables.scss';

.checkboxButton {
	height: $size-24;
	width: $size-24;
	border: $color-gray-4 solid 0.1rem;
	border-radius: $border-radius;
	background-color: $color-white;
	display: block;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.all {
	background-image: url('~assets/form-icons/check.svg');
}

.partial {
	background-image: url('~assets/form-icons/check-partial.svg');
}
