@import '~scss/variables.scss';

.tableWrapper {
	position: relative;
	overflow: auto;
	max-width: 100%;
}

.tableDom {
	@include basicTable;
}

.tr {
	.td {
		background: #f2f3f4;
		.notification & {
			@include tablet-down {
				background: transparent;
			}
		}
	}
	&:nth-of-type(odd) {
		.td {
			background: $color-gray-7;
			.notification & {
				@include tablet-down {
					background: transparent;
				}
			}
		}
	}
}

.td,
.th {
	padding: 1rem 1.2rem;
	@include tablet-up {
		padding: 1.4rem 2rem;
	}
	overflow: hidden;
	text-overflow: ellipsis;

	border-bottom: 0.1rem solid $color-white;
	text-align: left;
}

.td {
	vertical-align: top;
}

.th {
	background: #dadfe1;
	font-weight: $font-weight-bold;
}

.headerItemInner {
	display: flex;
	align-items: center;
	text-transform: uppercase;
	font-size: 1.2rem;
	line-height: 1.6rem;
	height: 100%;
	text-align: left;
}

button.headerItemInner {
	cursor: pointer;
}

.headerLabel {
	margin-top: 0.2rem;
}

.viewAllLink {
	width: 100%;
	text-align: center;
}

.header {
	.data & {
		background-color: $color-gray-6;
		@include tablet-up {
			background-color: transparent;
		}
	}
	padding: 2rem 2rem 2rem 2rem;
	@include tablet-up {
		padding: 0;
		padding-bottom: 2rem;
	}
}

.title {
	width: 100%;
	@include type-block-title;
}

.footer {
	@include margin-small-top;
}

.link {
	border-radius: 0;
	width: 100%;
	padding-top: 1.85rem;
	span {
		justify-content: center;
	}
	&:before,
	&:after {
		border-radius: 0;
		@include tablet-up {
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}
	@include tablet-up {
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}
}

.sortIcon {
	margin-left: $size-8;
}

// .tableScroller {
// 	@include tablet-down {
// 		overflow-x: scroll;
// 		overflow-y: visible;
// 		width: 100%;
// 	}
// }

// .tableScroller {
// 	@include tablet-down {
// 		margin-left: 50%;
// 		overflow-x: scroll;
// 		overflow-y: visible;
// 		width: 50%;
// 		z-index: 0;
// 	}
// }

// @mixin static-column-properties {
// 	left: 0;
// 	position: absolute;
// 	top: auto;
// 	width: 50%;
// 	z-index: 3;
// 	border-right: 1px solid #b2b9bd;
// 	// display: flex;
// 	// align-items: center;
// 	&:after {
// 		content: '';
// 		box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
// 		height: 100%;
// 		top: 0;
// 		right: -2px;
// 		left: initial;
// 		position: absolute;
// 		opacity: 0.4;
// 		width: 4px;
// 		z-index: 2;
// 		background: none;
// 	}
// }

// .td,
// .th {
// 	@include tablet-down {
// 		&:first-of-type {
// 			min-width: 50%;
// 			@include static-column-properties;
// 		}
// 	}
// }

// .isFirstColumnHiddenOnMobile {
// 	.td,
// 	.th {
// 		@include tablet-down {
// 			&:first-of-type {
// 				display: none;
// 			}
// 			&:nth-of-type(2) {
// 				@include static-column-properties;
// 			}
// 		}
// 	}
// }
.isSingleColumnOnMobile {
	@include tablet-down {
		.tableScroller {
			width: 100%;
			margin-left: 0;
		}
	}
}

.screenReaderTextMobile {
	@include tablet-down {
		@include screen-reader-text;
	}
}

.columnSelect {
	width: 5.6rem;
	@include tablet-down {
		display: none;
	}
}

.mainLinkFocusRing.mainLinkFocusRing.mainLinkFocusRing.mainLinkFocusRing {
	border-radius: 0;
	border-bottom-left-radius: 0.85rem;
	border-bottom-right-radius: 0.85rem;
}

.message {
	background-color: $color-gray-7;
	@include padding-small;
}
