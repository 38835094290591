@import '~scss/variables.scss';

.tabs {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	overflow-x: scroll;
	// @include desktop-up {
	// 	margin-bottom: -45px;
	// 	padding-bottom: 28px;
	// }
	background: $color-blue-dark;
	color: white;

	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
	&::-webkit-scrollbar {
		background: transparent; /* Chrome/Safari/Webkit */
		width: 0px;
		height: 0px;
	}
	&:after {
		content: '';
		border-bottom: 0.4rem solid transparent;
		width: 100%;
	}
	li {
		flex-shrink: 0;

		a,
		button {
			@include padding-small;
			display: block;
			transition: all 0.3s ease;
			border-bottom: 0.4rem solid transparent;
			min-width: 17rem;
			text-align: center;
			color: #c4c4c4;
			text-decoration: none !important;
			&:hover {
				color: white;
			}
			&.selected {
				border-bottom: 0.4rem solid $color-green-base;
				color: white;
				background: #2b3d6c;
			}
		}
	}
}

.scrollContainer {
	overflow-y: hidden;
}
