@import '~scss/variables.scss';

.costItems {
	overflow: auto;
	border: none;
	width: 100%;
	margin-bottom: 3rem;
	@include basicTable;
}
.costItemRow {
	&:nth-of-type(even) td {
		background: #f2f3f4;
	}
	td {
		height: 7.2rem;
	}
	th,
	td {
		background: #fcfcfc;
		padding: 0.4rem 1rem;
		border: none;
		position: relative;

		strong {
			color: $color-blue-dark;
			font-size: 1.4rem;
		}
		input {
			margin: 0;
		}
		width: 12rem;
		min-width: 12rem;
		&.largest {
			width: 26rem;
			min-width: 26rem;
		}
		&.large {
			width: 16rem;
			min-width: 16rem;
		}

		&.medium {
			width: 9rem;
			min-width: 9rem;
		}
		&.small {
			width: 5rem;
			min-width: 5rem;
		}
		&.smallest {
			width: 3rem;
			min-width: 3rem;
		}
		button {
			min-width: 0;
		}
	}
	th {
		background: #dbdfe1;
		text-align: left;
		padding-top: 1rem;
		padding-bottom: 1rem;
		line-height: 1.1;
		height: 4rem;
		.thInner {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.text {
				margin-right: 0.4rem;
			}
		}

		.icon {
			vertical-align: -0.5rem;
			margin-left: 0.3rem;
		}
	}
}

.payer {
	@include margin-large-bottom;
}

.pageContainer {
	margin-top: 5rem;
}

.spreadsheetLink {
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 2rem;
	margin-left: auto;
	margin-right: 9rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	.text {
		@media (max-width: 760px) {
			margin-top: 2rem;
		}
		@media (min-width: 760px) {
			max-width: calc(100% - 3.5rem);
		}
	}
	svg {
		transform: translate(10px, -8px);
		@media (max-width: 760px) {
			display: none;
		}
	}
}

.uppercase {
	text-transform: uppercase;
}

.livesNeededHeading {
	display: flex;
	align-items: center;
	text-transform: none;
	color: #262626;
	margin: 0;
	.icon {
		background: $color-green-base;
		border-radius: 50%;
		padding: 0.6rem;
		margin-right: 1.2rem;
	}
	strong {
		margin-left: 0.4rem;
		font-size: 2.2rem;
	}
}

.summaryTabContent {
	padding: 4rem 2rem;
	background: #f7f8f8;
	table {
		@include basicTable;
		@include margin-medium-bottom;
		th {
			background: #dbdfe1;
		}
		td {
			background: white;
		}
		th,
		td {
			padding: 1rem;
		}
		.amount {
			color: $color-green-dark;
			font-weight: 800;
			font-size: 20px;
			line-height: 20px;
		}
		.thInner {
			display: flex;
			align-items: center;
			.thLabel {
				margin-right: 0.4rem;
			}
		}
		.totalColumn {
			background: $color-gray-button !important;
			font-weight: bold;
		}
	}
}

.headingNoMargin {
	margin-bottom: 0;
}

.utilizationSection {
	margin-top: 0;
}
.payerSection {
	margin-bottom: 4rem;
}

.formTitleAndReset {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	h3 {
		margin: 0;
	}
}
