@import '~scss/variables.scss';

.summaryLink {
	font-size: 1.6rem;
	margin-top: 1.5rem;
}
.assessmentNameSplit {
	@include tablet-up {
		display: flex;
		align-items: center;
	}
	@include margin-large-bottom;
	.assessmentName {
		width: 100%;
		margin: 0 1rem 0 0;
		input {
			margin: 0;
			@include tablet-down {
				margin-bottom: 2rem;
			}
		}
	}
	a {
		margin: 0;
	}
}

.manageEditView {
	display: flex;
	.spacer {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.assessmentColumn {
	width: 25rem;
	@include desktop-large-up {
		width: 35rem;
	}
}

.assessmentTitle {
	h2 {
		color: inherit;
	}
}

.assessmentTable {
	td {
		font-size: 1.4rem;
		.constrainedText {
			max-width: 50rem;
		}
	}
	h2 {
		text-transform: uppercase;
	}
	.smallColumn {
		width: 18rem;
	}
	.columnHiddenOnMobile {
		@include desktop-down {
			display: none;
		}
	}
}

.assessmentDetails li {
	display: flex;
	a {
		text-decoration: underline;
		font-weight: normal;
	}
	.label {
		padding: 0 0.4rem;
	}
}
