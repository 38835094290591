@import '~scss/variables.scss';

.buttonPagination {
	margin-top: 3rem;
	margin-bottom: 3rem;
	@include mobile-up {
		display: flex;
		justify-content: flex-end;
		& > *:last-child {
			@include margin-small-left;
		}
	}
	@include mobile-down {
		& > *:last-child {
			margin-top: 2rem;
		}
	}
}

.instructions {
	margin-left: auto;
	display: table;
	margin-right: 2rem;
}
