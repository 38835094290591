@import '~scss/variables.scss';
@import '~scss/forms.scss';

.inputFrame {
	display: flex;
	flex-direction: column;
	border: none;
	padding: 0;
	margin-bottom: $size-12;
	margin-left: 0;
	margin-right: 0;
	&:last-child {
		margin-bottom: 0;
	}
}

.inputLabel {
	@include label;
}

.tooltipContainer {
	white-space: nowrap;
	.label {
		white-space: normal;
		display: inline;
	}
}

.tooltip {
	margin-top: -0.2rem;
	display: inline;
	margin-bottom: -0.5rem;
}
