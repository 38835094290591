@import '~scss/variables.scss';

.radioGroup {
	@include tablet-up {
		display: flex;
		align-items: center;
		justify-content: stretch;
	}
	@include margin-large-bottom;
	& > * {
		margin-right: 0.8rem;
		width: 100%;
		&:last-of-type {
			margin-right: 0;
		}
	}
}
