@import '~scss/variables.scss';

.checkboxGroup.type-button {
	@include mobile-up {
		display: flex;
		flex-wrap: wrap;
	}
	align-items: center;
	.checkbox {
		@include desktop-up {
			margin-right: 2rem;
			width: calc(33.33% - 1.3334rem);
			&:nth-of-type(3n) {
				margin-right: 0;
			}
		}
		@include desktop-down {
			&:nth-of-type(n) {
				margin-right: 2rem;
				width: calc(50% - 1rem);
			}
			&:nth-of-type(2n) {
				margin-right: 0;
			}
		}
		@include mobile-down {
			&:nth-of-type(n) {
				margin-right: 0;
				width: 100%;
			}
		}
	}
}
