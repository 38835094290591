@import '~scss/variables.scss';

.pageFrame {
	&.hasSidebar {
		@include desktop-up {
			display: flex;
			align-items: stretch;
		}
		.pageFrameTop {
			margin-bottom: 3rem;
		}
		.mainContent {
			width: calc(100% - 20rem);
			min-height: calc(100vh - 11rem - 5rem);
			@include desktop-large-down {
				width: 100%;
			}

			.container {
				// @include padding-large-vertical;
				padding: 5rem 0 6rem 0;
			}
		}
		.helpSidebar {
			.inner {
				@include padding-medium;
				padding-top: 3rem !important;
				padding-bottom: 19rem !important;
			}
			p {
				margin: 0.4rem 0 1rem 0;
				font-size: 1.3rem;
			}
			.learnMore,
			strong {
				text-transform: uppercase;
				margin-top: 2.4rem;
				display: block;
			}

			background: $color-gray-6;
			@include desktop-up {
				max-width: 33.1rem;
				min-width: 20rem;
				width: 33.1rem;
				// height of window minus header and footer

				position: relative;
			}
			.sidebarBottom {
				@include desktop-up {
					position: absolute;
					bottom: 0;
					right: 0;
				}
				@include padding-medium;
				width: 100%;
				background: $color-gray-progressHeader;
			}
		}
	}
}
.mainContent {
	min-height: calc(100vh - 5rem);
}

.pageFrameTop {
	@include tablet-up {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.pageFrameTopInner {
	min-width: 0;
	h1 {
		margin: 0;
	}
}

.pageFrameTitle {
	margin-top: 0;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.backLocation {
	display: inline-block;
}

.backLocationIcon {
	margin-right: 0.5rem;
}

.contentTopRight {
	@include margin-medium-top;
	@include tablet-up {
		margin-top: -0.8rem;
		margin-bottom: -0.8rem;
	}
}

.breadcrumbs {
	margin-bottom: 1.2rem;
}

.footer {
	display: flex;
	align-items: stretch;
	height: 5rem;
	background: white;
	justify-content: space-between;
	@include padding-medium-horizontal;
	position: relative;
	z-index: 5;
	box-shadow: 12px 0px 30px rgba(0, 0, 0, 0.08);
	text-transform: uppercase;
	.copyright {
		font-size: 1rem;
		color: $color-gray-brand;
		margin-left: auto;
	}
	& > * {
		display: flex;
		align-items: center;
	}
}
