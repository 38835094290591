@import '~scss/variables.scss';

.summaryTabContent {
	padding: 4rem 2rem;
	background: #f7f8f8;
	table {
		@include basicTable;
		@include margin-large-bottom;
		th {
			background: #dbdfe1;
		}
		td {
			background: white;
		}
		th,
		td {
			padding: 1rem;
		}
		.amount {
			color: $color-green-dark;
			font-weight: 800;
			font-size: 20px;
			line-height: 20px;
		}
		.thInner {
			display: flex;
			align-items: center;
			.thLabel {
				margin-right: 0.4rem;
			}
		}
		.totalColumn {
			background: $color-gray-button !important;
			font-weight: bold;
		}
	}
}

.livesNeededHeading {
	@include tablet-up {
		display: flex;
		align-items: center;
	}
	text-transform: none;
	color: #262626;
	margin: 0;
	.icon {
		background: $color-green-base;
		border-radius: 50%;
		padding: 0.6rem;
		margin-right: 1.2rem;
	}
	strong {
		margin-left: 0.4rem;
	}
}

.chartsSplit {
	@include desktop-up {
		display: flex;
	}
	& > * {
		@include desktop-up {
			width: calc(50% - 1rem);
			margin-right: 2rem;
		}

		&:last-of-type {
			margin-right: 0;
			@include desktop-down {
				margin-top: 2rem;
			}
		}
	}
}

:global {
	.highcharts-container {
		font-family: $font-family-national !important;
		.highcharts-title {
			font-weight: bold !important;
			fill: $color-blue-dark !important;
		}
		.highcharts-axis-title {
			transform: translateY(17px);
		}
	}
}

.totalsPanel {
	background: $color-gray-6;
	padding: 3rem 2rem;
	text-align: center;
	@include tablet-up {
		display: flex;
		align-items: center;
		& > * {
			margin-right: 1.5rem;
			width: calc(33.33% - 1rem);
			&:last-of-type {
				margin-right: none;
			}
		}
	}
	h6 {
		font-size: 12px;
		margin: 0;
		line-height: 1.4;
	}
	.amount {
		font-size: 2rem;
		line-height: 2rem;
		font-weight: bold;
		color: $color-green-dark;
		margin: 0 0 0.8rem 0;
	}
}

.uppercase {
	text-transform: uppercase;
}
