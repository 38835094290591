@import 'src/scss/mixins/browsers.scss';

// Font families
$font-family-national: 'National2Web', 'Segoe UI', Tahoma, sans-serif;
$font-family-volkhov: 'Volkhov', 'Segoe UI', Tahoma, sans-serif;

// Font weights
$font-weight-light: 100;
$font-weight-regular: 400;
$font-weight-semiBold: 600;
$font-weight-bold: 700;

// Colors
$color-green-light: #8ec759;
$color-green-base: #94ca53;
$color-green-dark: #567d26;

$color-blue-light: #385fb2;
$color-blue-base: #1c3063;
$color-blue-dark: #1f3160;

$color-purple-base: #3c3e9f;

$color-red-light: #e18a8f;
$color-red-base: #a50101;
$color-red-dark: #9c0e18;
$color-red-darker: #850b13;

$color-yellow-base: #e3af4a;

$color-orange-base: #ca7e0c;

$color-gradient-gray: linear-gradient(179.59deg, #27373e -0.28%, #324349 98.25%);

// Neutrals
$color-gray-1: #27363e;
$color-gray-2: #3b4c54;
$color-gray-3: #64747d;
$color-gray-4: #b2b9bd;
$color-gray-5: #e5e5e5;
$color-gray-6: #f7f7f7;
$color-gray-7: #fcfcfc;
$color-white: #ffffff;
$color-gray-brand: #757575;
$color-gray-body: #595959;
$color-gray-progressHeader: #f2f3f4;
$color-gray-button: #bec4d1;

$color-transparent: transparent;

$box-shadow-small: 0px 4px 8px rgba(0, 0, 0, 0.06);
$box-shadow-large: rgba(9, 30, 66, 0.13) 0px 4px 11px;

// Z-index
$z-index-focused-panel-trigger: 1;
$z-index-button-content: 1;
$z-index-sidebar: 50;
$z-index-message: 50;
$z-index-dropdown-content: 75;
$z-index-dropdown: 75;
$z-index-modal-backdrop: 80;
$z-index-tooltip: 100;
$z-index-menu-toggle: 120;
$z-index-skip-navigation: 125;

// Size
// These are commonly used sizes accross the application to create consistency among spacing throughout the application. If you need a space other than these, consider whether the exception is necessary.
$size-2: 0.2rem;
$size-4: 0.4rem;
$size-8: 0.8rem;
$size-12: 1.2rem;
$size-16: 1.6rem;
$size-24: 2.4rem;
$size-32: 3.2rem;
$size-40: 4rem;
$size-48: 4.8rem;

//Reusable sizes for specific use cases
$size-gutter: 1.6rem;
$size-column: 9.6rem;
$size-container-margin-mobile: 2rem;
$size-container-margin-desktop: 3rem;

// Breakpoint
$breakpoint-mobile-small: 320;
$breakpoint-mobile: 480;
$breakpoint-tablet: 768;
$breakpoint-desktop: 1024;
$breakpoint-desktop-large: 1200;
$breakpoint-desktop-larger: 1800;

// Container
$container-width: 132.8;

// Border-radius
$border-radius: 0.5rem;
$border-radius-large: 1rem;

// Typography
$type-040-font-size-desktop: 0.8rem;
$type-040-line-height-desktop: 1.2rem;
$type-040-font-size-mobile: 0.8rem;
$type-040-line-height-mobile: 1.2rem;

$type-060-font-size-desktop: 1rem;
$type-060-line-height-desktop: 1.6rem;
$type-060-font-size-mobile: 1rem;
$type-060-line-height-mobile: 1.6rem;

$type-080-font-size-desktop: 1.2rem;
$type-080-line-height-desktop: 1.6rem;
$type-080-font-size-mobile: 1.2rem;
$type-080-line-height-mobile: 1.6rem;

$type-100-font-size-desktop: 1.4rem;
$type-100-line-height-desktop: 2rem;
$type-100-font-size-mobile: 1.4rem;
$type-100-line-height-mobile: 2rem;

$type-200-font-size-desktop: 1.6rem;
$type-200-line-height-desktop: 2.4rem;
$type-200-font-size-mobile: 1.6rem;
$type-200-line-height-mobile: 2.4rem;

$type-300-font-size-desktop: 2rem;
$type-300-line-height-desktop: 2.8rem;
$type-300-font-size-mobile: 2rem;
$type-300-line-height-mobile: 2.8rem;

$type-400-font-size-desktop: 2.4rem;
$type-400-line-height-desktop: 3.2rem;
$type-400-font-size-mobile: 2.4rem;
$type-400-line-height-mobile: 3.2rem;

$type-500-font-size-desktop: 2.8rem;
$type-500-line-height-desktop: 4rem;
$type-500-font-size-mobile: 2.8rem;
$type-500-line-height-mobile: 4rem;

$type-600-font-size-desktop: 3.6rem;
$type-600-line-height-desktop: 4.4rem;
$type-600-font-size-mobile: 3.6rem;
$type-600-line-height-mobile: 4.4rem;

$type-700-font-size-desktop: 4rem;
$type-700-line-height-desktop: 4.8rem;
$type-700-font-size-mobile: 4rem;
$type-700-line-height-mobile: 4.8rem;

$type-800-font-size-desktop: 5.2rem;
$type-800-line-height-desktop: 6.4rem;
$type-800-font-size-mobile: 5.2rem;
$type-800-line-height-mobile: 6.4rem;

$type-block-title-font-size-desktop: 1.8rem;
$type-block-title-line-height-desktop: 2.4rem;
$type-block-title-font-size-mobile: 1.6rem;
$type-block-title-line-height-mobile: 2rem;

@import 'mixins/accessibility';
@import 'mixins/breakpoints';
@import 'mixins/spacing';
@import 'mixins/typography';
@import 'mixins/svg';
@import 'mixins/buttons.module';
@import 'mixins/gridWidth';
@import 'mixins/table';
