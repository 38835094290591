@import '~scss/variables.scss';

.dropdownlist {
	padding: 0;
	margin: 0;
	padding: $size-12 0;
	li {
		list-style: none;
		padding: 0;
		margin: 0;
		a,
		button {
			display: block;
			text-decoration: none;
			font-size: 1.4rem;
			padding: 1rem 2rem;
			width: 100%;
			text-align: left;
			& > * {
				justify-content: flex-start;
			}
			&:hover {
				background: $color-gray-7;
				text-decoration: none;
			}
		}
		transform: translateY(0);
	}
}

.dropdownItemLink {
	display: block;
	padding: $size-8 $size-24;
	transition: all 0.3s;
	&:hover {
		background-color: $color-gray-6;
		text-decoration: none;
		transition: all 0.3s;
	}
	&:active {
		background-color: $color-gray-5;
		transition: all 0.3s;
	}
}

.buttonContent {
	justify-content: flex-start;
}
