@import '~scss/variables.scss';

.input {
	position: absolute !important;
	height: 0.1rem;
	width: 0.1rem;
	overflow: hidden;
	transform: translateY(1.2rem);
	clip: rect(0.1rem 0.1rem 0.1rem 0.1rem); /* IE6, IE7 */
	clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
	cursor: pointer;
}

.label {
	position: relative;
	display: inline-block;
	font-size: 1.8rem;
	text-transform: none;
	padding-left: 4.8rem;
	transform: translateY(-1px);
	padding-top: 1.2rem;
	padding-bottom: $size-4;
	cursor: pointer;
	color: $color-blue-dark;
}

.label::before,
.label::after {
	position: absolute;
	content: '';

	/*Needed for the line-height to take effect*/
	display: inline-block;
}

/*Outer box of the fake checkbox*/
.label::before {
	height: 3.6rem;
	width: 3.6rem;
	border: 0.1rem solid $color-gray-button;
	background: $color-gray-button;
	border-radius: 100%;
	left: 0;
	top: 0.3rem;
	background-repeat: no-repeat;
	background-position: center;
}

.isLabelHidden::before {
	top: -0.8rem;
}

.input:checked + .label::after {
	height: 2.8rem;
	width: 2.8rem;
	display: block;
	background-color: $color-blue-base;
	top: 0.7rem;
	left: 0.4rem;
	border-radius: 100%;
}

/*Adding focus styles on the outer-box of the fake checkbox*/

:global {
	.user-is-tabbing {
		:local {
			.input:focus + .label::before {
				outline: $color-blue-light auto 0.4rem;
			}
		}
	}
}

/*Adding style for disabled checkbox*/
.input:disabled + .label {
	color: $color-gray-6;
}

.input:disabled + .label::before {
	background: $color-gray-6;
	border: 0.1rem solid $color-gray-6;
}

.error {
	color: $color-red-base;
}

.label {
	user-select: none;
}

.type-button {
	.input + .label {
		padding: 2rem 4rem;
		border: 0.2rem solid transparent;
		border-radius: 10rem;
		background: $color-gray-button;
		width: 100%;
		font-size: 1.8rem;
		text-transform: none;
		color: $color-blue-base;
	}
	.input:checked + .label {
		border-color: $color-blue-dark;
		background-image: url('~assets/svg/check.svg');
		background-repeat: no-repeat;
		background-position: 3.6rem center;
		padding-left: 7rem;
	}
	.label::before,
	.label::after {
		display: none;
		content: none;
	}
}
