@import '~scss/variables';
.header {
	display: flex;
	@include tablet-up {
		height: 11rem;
	}
	align-items: stretch;
	nav {
		display: flex;
		width: 100%;
		text-transform: uppercase;
		align-items: stretch;
		box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.08);
		position: relative;
		z-index: 3;
		@include tablet-down {
			text-align: center;
			flex-wrap: wrap;
			padding: 2rem 0 0 0;
			align-items: center;
			justify-content: center;
		}
		& > * {
			display: flex !important;
			align-items: center;
			justify-content: center;
		}
	}

	.navItem {
		display: block;

		@include padding-small-horizontal;
		font-size: 2rem;
		border-bottom: 0.3rem solid transparent;
		text-decoration: none;
		color: $color-blue-base;
		font-weight: $font-weight-regular;
		text-transform: uppercase;
		@include tablet-down {
			font-size: 1.5rem;
			text-align: left;
			padding: 1.5rem 0;
			padding-right: 1rem;
			margin-right: 2rem;
			&:last-child {
				padding-right: 0;
				margin-right: 0;
			}
		}
		&.active {
			border-color: $color-green-base;
		}
		&:hover {
			color: $color-blue-light;
		}
	}
	.logoutMobileLink {
		display: block;
		@include tablet-up {
			display: none !important;
		}
	}
	.logo {
		@include padding-medium-horizontal;
		text-align: center;
		flex-wrap: wrap;
		text-decoration: none;
		@include tablet-down {
			width: 100%;
			justify-content: stretch;
			margin-bottom: 1rem;
			& > span {
				margin: auto;
			}
		}

		.firstLine {
			color: $color-blue-base;
			font-size: 3rem;
			margin-bottom: 0.4rem;
			@include desktop-down {
				font-size: 2.4rem;
			}
		}
		.secondLine {
			font-size: 1.8rem;
			letter-spacing: 5px;
			font-weight: $font-weight-regular;
			color: $color-gray-brand;
		}
	}

	.account {
		margin-left: auto;
		@include padding-medium-horizontal;

		@include tablet-up {
			width: 33.1rem;
			border-left: 1px solid $color-gray-5;
		}

		@include tablet-down {
			display: none !important;
		}

		button {
			color: $color-gray-brand;
			font-weight: $font-weight-regular;
			width: 100%;
			padding: 2rem 1rem;
			&:hover {
				color: $color-blue-light !important;
			}
			& > span {
				width: 100%;
			}
			svg:first-of-type {
				margin-right: 1.4rem;
			}
			.dropdownCaret {
				margin-left: auto;
				@include all-svg-elements {
					stroke: $color-green-base !important;
				}
			}
		}
	}

	.loginLink {
		@include tablet-up {
			margin-left: auto;
			margin-right: $size-gutter;
		}
	}
}
