@import '~scss/variables.scss';
@import '~components/StorybookComponents/scss/storybook-variables.scss';

.section {
	@include margin-large-vertical;
	&:first-child {
		margin-top: 0;
		@include tablet-up {
			margin-top: 0;
		}
	}
}

.titleContainer {
	white-space: nowrap;
	.title {
		white-space: normal;
	}
	& > * {
		display: inline;
	}
}

.description {
	margin-top: $size-12;
}

.content {
	&.hasTitleOrDescription {
		@include margin-medium-top;
	}
}

.title {
	margin-top: 0;
}

.titleIcon {
	position: relative;
	top: 0.3rem;
}
