@import '~scss/variables.scss';
@import '~scss/forms.scss';

.inputContainer {
	position: relative;
}

.input {
	@include input;
}
.hasIcon {
	padding-right: $size-40;
}

.icon {
	position: absolute;
	top: 50%;
	right: 2rem;
	transform: translateY(-50%);
}

.error {
	border: 0.2rem solid $color-red-base;
}
