@import '~scss/variables.scss';

.dropdownWrapper {
	position: relative;
	display: table;
	width: 100%;
	@include tablet-up {
		width: auto;
	}
	&.positionRight {
		.dropdownContentArea {
			left: initial;
			right: 0;
		}
	}
}

.dropdownContentArea {
	background: $color-white;
	position: absolute;
	bottom: 0;
	transform: translateY(100%);
	left: 0;
	border-radius: $border-radius;
	width: 28.4rem;
	z-index: $z-index-dropdown-content;
	box-shadow: $box-shadow-large;
}
