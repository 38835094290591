body,
input,
textarea,
select,
button {
	font-family: $font-family-national;
	@include type-body;
	color: $color-gray-1;
}

// This keeps mobile Safari from zooming in on the input fields
input,
textarea,
select {
	@include mobile-down {
		@include type-200;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: $font-family-national;
	font-weight: $font-weight-bold;
	margin-top: 0;
	margin-bottom: 1.5rem;
	color: $color-blue-base;
}

.h0 {
	font-weight: 800;
	font-size: 3.2rem;
	line-height: 3.8rem;
}
h1,
.h1 {
	font-weight: 800;
	font-size: 3rem;
	line-height: 3.6rem;
	color: $color-blue-dark;
}
h2,
.h2 {
	font-weight: 800;
	font-size: 2rem;
	line-height: 2.4rem;
	color: $color-gray-brand;
}
h3,
.h3 {
	text-transform: none;
	font-weight: 800;
	font-size: 1.8rem;
	line-height: 2rem;
	color: $color-gray-brand;
}
h4,
.h4 {
	font-weight: 800;
	font-size: 1.8rem;
	line-height: 1.6rem;
	color: $color-blue-dark;
}
h5,
.h5 {
	font-weight: 800;
	font-size: 1.4rem;
	line-height: 1.7rem;
	color: $color-blue-dark;
}
h6,
.h6 {
	font-weight: normal;
	font-size: 1.4rem;
	line-height: 1.8rem;
	color: $color-gray-brand;
}
.h7 {
	font-weight: 800;
	font-size: 12px;
	line-height: 15px;
	color: $color-blue-dark;
	text-transform: uppercase;
}
.largeParagraph {
	font-size: 2rem;
	line-height: 2.6rem;
	color: $color-gray-brand;
}
a {
	@include link;
}

ol,
ul {
	padding-inline-start: unset;
}

ul {
	list-style: none;
}

ol {
	padding-left: 1.4rem;
	margin: 2rem 0;
}

p,
li {
	font-family: $font-family-national;
	font-weight: $font-weight-regular;
	margin: 2rem 0;
	@include type-body;
}

li {
	margin: 0 0;
}

p li {
	transform: translateY(0);
}

br {
	margin-bottom: 2.6rem;
}

.blue {
	color: $color-blue-dark;
}
.underline {
	text-decoration: underline;
}
.uppercase {
	text-transform: uppercase;
}
