@import '~scss/variables.scss';

.assessmentsTable {
	td {
		font-size: 1.6rem;
	}
}

.manageOptions {
	display: flex;
	a,
	button {
		font-size: 1.6rem;
	}
	.separator {
		padding-left: 0.4rem;
		padding-right: 0.4rem;
	}
}

.startNewButton {
	@include tablet-up {
		min-width: 31rem;
	}
}
