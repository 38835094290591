@import '~scss/variables.scss';

.hero {
	@include desktop-up {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}
	max-width: 160rem;
	margin: auto;
	.left {
		max-width: 77.5rem;
		margin: 0 auto;
		width: 100%;
		padding-top: 4rem;
		@include desktop-up {
			padding-top: 11.8rem;
		}
		.firstHeading {
			color: $color-green-base;
			margin: 0 0 2.5rem;
			text-transform: uppercase;
		}
		.secondHeading {
			font-size: 4rem;
			margin: 0;
			line-height: 1;
			font-family: $font-family-volkhov;
			text-transform: none;
			.underlinedBrushStroke {
				background: url('~assets/png/underline-brush-stroke.png') no-repeat bottom left;
				background-position-y: 9.4rem;
				background-size: 56rem;
				overflow: visible;
				@media (max-width: 1223px) {
					background: none;
				}
			}

			@include mobile-up {
				font-size: 5rem;
			}
			@include tablet-up {
				font-size: 6.6rem;
				.underlinedBrushStroke {
					background-position-y: 7.4rem !important;
				}
			}
			@media (min-width: 1400px) {
				font-size: 7.5rem;
				.underlinedBrushStroke {
					background-position-y: 8.4rem !important;
				}
			}
			@media (min-width: 1700px) {
				font-size: 8.4rem;
			}
		}
		button {
			box-shadow: 0px 20px 35px rgba(241, 165, 1, 0.15);
		}
	}
	.right {
		@include desktop-up {
			min-height: 71rem;
			min-width: 50%;
		}
		@include desktop-down {
			min-height: 55rem;
			width: 100%;
			background-position: center right;
		}

		background: url('~assets/png/home-hero.png') no-repeat center;
		background-size: cover;
		background-position: top center;
		display: flex;
		align-items: flex-end;

		.infoPanel {
			background: white;
			border-radius: 2.5rem;
			margin-bottom: 8.5rem;
			position: relative;
			padding: 3.4rem 3.4rem;
			filter: drop-shadow(0px 12px 30px rgba(0, 0, 0, 0.12));
			max-width: 46rem;
			h5,
			p {
				margin: 0;
			}
			p {
				@include type-body-large;
			}
			.iconWrapper {
				background-color: $color-green-base;
				border-radius: 50%;
				position: absolute;
				padding: 1rem;
				width: 5.5rem;
				height: 5.5rem;
				top: -1.7rem;
				left: -1.7rem;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					height: 3.4rem !important;
					min-height: 3.4rem !important;
				}
			}
		}
	}
}

.blueBanner {
	background-color: $color-blue-base;
	text-align: center;
	min-height: 18.9rem;
	display: flex;
	align-items: center;
	.heading {
		text-transform: none;
		color: white;
		margin: 0;
		@include padding-large-vertical;
		font-size: 3.6rem;
		line-height: 4rem;
		font-family: $font-family-volkhov;
	}
}

.iconFeatures {
	background: $color-gray-6;
	@include padding-large-vertical;
	.container {
		@include desktop-up {
			display: flex;
		}
	}
	.iconFeature {
		@include desktop-down {
			&:first-of-type {
				margin-bottom: 3rem;
			}
		}
		@include desktop-up {
			width: 50%;
		}
		@include padding-large-horizontal;
		text-align: center;
		.icon {
			vertical-align: -0.6rem;
			margin-left: 0.4rem;
		}
		ul {
			text-align: left;
			li {
				list-style: disc;
				line-height: 4rem;
				margin-left: 2rem;
				font-size: 1.6rem;
			}
		}
		@include desktop-up {
			&:first-of-type {
				border-right: 2px solid #dbdfe1;
			}
		}
		.iconWrapper {
			background-color: white;
			border-radius: 50%;
			padding: 1rem;
			width: 15.5rem;
			height: 15.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto 2rem auto;
		}
	}

	.iconFeaturesHeading {
		color: $color-blue-base;
	}
}

.callToActionPanel {
	background: $color-blue-base;
	border-radius: 2rem;
	@include tablet-up {
		border-radius: 129px 20px 20px 20px;
	}
	color: white;
	display: flex;
	align-items: stretch;
	overflow: hidden;
	.iconWrapper {
		background-color: $color-green-base;
		border-radius: 50%;
		position: absolute;
		padding: 1rem;
		width: 7rem;
		height: 7rem;
		top: -2.2rem;
		right: -2.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			height: 2.9rem !important;
			min-height: 3.5rem !important;
			width: auto !important;
			min-width: 0 !important;
			overflow: visible;
			margin-top: 0.3rem;
			@include all-svg-elements {
				stroke-width: 1 !important;
			}
		}
	}
	.image {
		display: none;
		@include tablet-up {
			display: block;
			width: 50%;
			background: url('~assets/jpg/call-to-action.jpg') no-repeat center;
			background-size: cover;
			min-height: 40.7rem;
		}
	}
	.right {
		width: 100%;
		@include desktop-up {
			width: 50%;
		}
		padding: 3.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		.heading {
			color: white;
			text-transform: none;
		}
		p {
			@include type-body-large;
			margin: 3rem 0;
			color: white;
		}
		.text {
			max-width: 43rem;
			margin: 0 auto;
		}
	}
}
