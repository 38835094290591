@import '~scss/variables.scss';

.fieldset {
	border: none;
	&.legend-large {
		@include margin-large-top;
	}
}

.legend {
	padding: 0;
	&.legend-large {
		@include type-block-title;
		@include margin-medium-bottom;
	}
}

form {
	fieldset:first-child {
		margin-top: 0;
	}
}

.tooltipContainer {
	.legend {
		white-space: nowrap;
		.legendText {
			white-space: normal;
			display: inline;
		}
	}
}

.tooltip {
	margin-top: -0.3rem;
	margin-bottom: -0.5rem;
	display: inline;
}
