@import '~scss/variables.scss';

.backdrop {
	background: rgba(0, 0, 0, 0.7);
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
	z-index: $z-index-modal-backdrop;
	position: fixed;
}

.modal {
	display: flex;
	flex-direction: column;
	max-width: 80rem;
	margin: 6rem auto;
	font-size: 1.6rem;

	p {
		font-size: 1.6rem;
	}

	@include mobile-up {
		margin: 10rem auto;
	}
}

.inner {
	color: $color-gray-3;
	background-color: $color-white;
	padding: 1.4rem 2rem;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $color-blue-base;
	padding: 0 2rem;
	padding-top: $size-24;
	padding-bottom: $size-24;
	button {
		min-width: 0;
	}

	.headerTitle {
		margin: 0;
		@include type-block-title;
		color: $color-white;
		text-transform: none !important;
		@include tablet-up {
			color: $color-white;
		}
	}
}

.footer {
	background-color: $color-white;

	padding: 1.4rem 2rem 3.4rem 2rem;

	@include tablet-up {
		display: flex;
		justify-content: flex-start;
		flex-direction: row-reverse;
	}
}

.secondaryButton {
	margin-top: $size-16;
	@include tablet-up {
		margin-top: 0;
		margin-right: $size-8;
	}
}

.scrollContainer {
	overflow-y: scroll;
	margin-bottom: $size-48;
	height: 100vh;
}
