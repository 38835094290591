@import '~scss/variables.scss';

.costItems {
	overflow: auto;
	border: none;
	width: 100%;
	margin-bottom: 3rem;
	@include basicTable;
}
.costItemRow {
	&:nth-of-type(even) td {
		background: #f2f3f4;
	}
	th,
	td {
		background: #fcfcfc;
		padding: 0.4rem 1rem;
		border: none;
		position: relative;
		height: 7.2rem;
		strong {
			color: $color-blue-dark;
			font-size: 1.4rem;
		}
		input {
			margin: 0;
		}
		width: 12rem;
		min-width: 12rem;
		&.largest {
			width: 26rem;
			min-width: 26rem;
		}
		&.large {
			width: 16rem;
			min-width: 16rem;
		}

		&.medium {
			width: 9rem;
			min-width: 9rem;
		}
		&.small {
			width: 5rem;
			min-width: 5rem;
		}
		button {
			min-width: 0;
		}
	}
	th {
		background: #dbdfe1;
		text-align: left;
		padding-top: 1rem;
		padding-bottom: 1rem;
		line-height: 1.1;
		min-height: 2rem;
		height: 4rem;
		.thInner {
			display: flex;
			align-items: center;
			.text {
				margin-right: 0.4rem;
			}
		}
		.icon {
			vertical-align: -0.5rem;
			margin-left: 0.3rem;
		}
	}
}

.summaryTableRow {
	width: 25%;
	min-width: 10rem;
}

.summaryWrap {
	.costSummaryTotalColumn {
		background: $color-gray-button !important;
		font-weight: bold;
	}

	.costSummaryRoleColumn {
		font-weight: bold;
		color: $color-blue-dark;
		font-size: 1.4rem;
	}

	&.type-both {
		.summarySplit {
			& > * {
				margin-bottom: 3rem;
			}
			@include desktop-up {
				display: flex;
				& > * {
					width: calc(50% - 2rem);
					margin-right: 4rem;
					&:nth-of-type(2n) {
						margin-right: 0;
					}
				}
			}
		}
	}
}

.heading {
	margin-bottom: 4rem;
	text-transform: none;
}

.totalsPanel {
	background: $color-gray-6;
	padding: 2rem;
	@include tablet-up {
		display: flex;

		& > * {
			margin-right: 1.5rem;
			width: calc(33.33% - 1rem);
			&:last-of-type {
				margin-right: none;
			}
		}
	}
	h6 {
		font-size: 12px;
		margin: 0 0 0.8rem;
		line-height: 1.4;
		min-height: 3.3rem;
	}
	.amount {
		font-size: 2rem;
		line-height: 2rem;
		font-weight: bold;
		color: $color-green-dark;
	}
}
.headingOptionalNote {
	margin-top: -1rem;
	color: $color-blue-dark;
	font-size: 2rem;
	margin-bottom: 1rem;
}

.savingsPanel {
	background: $color-blue-dark;
	color: white;
	min-height: 9.7rem;
	display: flex;
	align-items: center;
	padding: 2rem 0;

	.iconWrapper {
		background-color: $color-green-base;
		border-radius: 50%;
		padding: 1rem;
		width: 4.1rem;
		height: 4.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1.5rem;
	}

	.savingsPanelContainer {
		display: flex;
		align-items: center;
	}

	.savingsAmountText {
		color: white;
		margin: 0;
		display: flex;
		align-items: center;
	}

	.savingsAmountValue {
		font-size: 3.6rem;
		margin-left: 1rem;
	}
}

.labelWithTooltip {
	display: flex;
	align-items: center;
	strong {
		margin-right: 1rem;
		display: block;
	}
}

.futureInvestmentsSummary {
	background: $color-gray-button;
	display: flex;
	@include padding-small;
	align-items: center;
	@include margin-small-top;
	h5 {
		margin: 0 1rem 0 0;
	}
	.icon {
		margin-right: 0.8rem;
	}
	.amount {
		color: $color-green-dark;
		font-size: 2rem;
		font-weight: bold;
	}
}

.summaryIntro {
	padding-top: 3rem;
	padding-bottom: 3rem;

	.accessibilityHeading {
		margin-bottom: 0;
	}

	.primaryHeading {
		margin-bottom: 0;
	}
}
