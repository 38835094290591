@import '~scss/variables.scss';

.tableMetaContent {
	@include desktop-large-up {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.contentArea {
	@include mobile-up {
		display: flex;
		justify-content: center;
	}
	@include tablet-up {
		justify-content: start;
	}
}

.contentAreaSpacing {
	margin-top: 2rem;
	@include desktop-large-up {
		margin-top: 0;
	}
}
