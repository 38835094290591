@import '~scss/variables.scss';
.signInPageFrame {
	overflow: hidden;
	main {
		overflow: hidden;
	}
}
.signIn {
	@include tablet-up {
		height: calc(100vh - 5rem);
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
	}
	background: $color-blue-dark;
	.content {
		display: flex;
		align-items: center;
		height: 100%;
		background: white;
		@include tablet-down {
			padding-top: 6rem;
			padding-bottom: 6rem;
		}
		@include tablet-up {
			width: 40%;

			.container {
				max-width: 40rem;
			}
		}
	}
	.image {
		background: url('~assets/png/signin-background.png') no-repeat center;
		background-size: cover;
		background-position: bottom right;
		@include tablet-down {
			padding-top: 4rem;
			padding-bottom: 4rem;
		}
		@include tablet-up {
			width: 60%;
			min-height: 107.6rem;
		}
		position: relative;
		display: flex;
		align-items: center;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $color-blue-dark;
			opacity: 0.92;
		}
		.imageContent {
			color: white;
			max-width: 70rem;
			margin: 0 auto;
			position: relative;
			z-index: 3;
			.heading,
			p {
				color: white;
				strong {
					color: $color-green-base;
				}
			}
			.heading {
				font-family: $font-family-volkhov;
				font-weight: bold;
				font-size: 3.2rem;
				line-height: 4.8rem;
			}
			p {
				font-size: 2rem;
				line-height: 3.6rem;
			}
		}
	}

	.logo {
		text-align: center;
		flex-wrap: wrap;
		text-decoration: none;
		text-transform: uppercase;
		font-family: $font-family-national;
		margin-bottom: 3rem;

		.firstLine {
			color: $color-blue-base;
			font-size: 3rem;
			margin-bottom: 0.4rem;
			font-weight: 800;
		}
		.secondLine {
			font-size: 1.8rem;
			letter-spacing: 5px;
			font-weight: $font-weight-regular;
			color: $color-gray-brand;
		}
	}
	:global {
		.firebaseui-container.mdl-card {
			box-shadow: none;
		}
		.firebaseui-card-content,
		.firebaseui-card-header,
		.firebaseui-card-actions {
			padding: 0;
		}
		.firebaseui-container {
			margin: 0 auto;
		}
		.firebaseui-idp-button,
		.firebaseui-tenant-button {
			max-width: none;
		}
	}
}
